import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';

import Markdown from 'components/common/markdown/Markdown';
import Section from 'components/common/section/Section';
import { Wrapper } from 'components/common/wrapper/Wrapper';
import Ads from 'components/directus/ads/Ads';
import BankAdsGrid from 'components/directus/bank-ads-grid/BankAdsGrid';
import DiscountedAddressServices from 'components/directus/discounted-address-services/DiscountedAddressServices';
import FaqInfoPanel from 'components/directus/faq-info-panel/FaqInfoPanel';
import PackageInclusions from 'components/directus/package-inclusions/PackageInclusions';
import PageTitle from 'components/directus/page-title/PageTitle';
import TrustPilotBanner from 'components/directus/trustpilot-banner/TrustPilotBanner';
import { ServicesRichSnippet } from 'components/common/rich-snippets';
import { DirectusPageData } from 'interfaces/directus-page-data.interface';
import packagePageHook from 'hooks/package-page/package-page.hook';
import { getFieldBySlug } from 'utils/directus';
import { PageProps } from 'utils/gatsby.interface';

import s from 'pages-styles/package/digital-plus-package.scss';

export default ({ data }: PageProps<DirectusPageData>): JSX.Element => {
    const slug = 'digital-plus-package';
    const { node } = getFieldBySlug(slug, data.allDirectusPage.edges);
    const { digitalPlusPackage } = packagePageHook();

    return (
        <>
            <Helmet>
                <title>{node.title}</title>
                <meta name="title" content={node.title} />
                <meta name="description" content={node.description} />
                <meta name="robots" content={node.robots} />
                <link rel="canonical" href={node.canonical} />
            </Helmet>

            <Wrapper>
                <Section bottom={0}>
                    <PageTitle slug={slug} />
                </Section>
                <Section bottom={25}>
                    <Markdown source={digitalPlusPackage.content} container className={s.digitalPlusPackage__content} />
                </Section>
                <Section bottom={50}>
                    <PackageInclusions slug={slug} />
                </Section>
                <Section bottom={50}>
                    <Ads slug="telephone-order" />
                </Section>
                <Section bottom={30}>
                    <FaqInfoPanel slug={slug} />
                </Section>
                <Section bottom={50}>
                    <Markdown source={digitalPlusPackage.footnote} container className={s.digitalPlusPackage__footnote} />
                </Section>
                <Section>
                    <DiscountedAddressServices />
                </Section>
                <Section>
                    <BankAdsGrid />
                </Section>
                <Section>
                    <TrustPilotBanner />
                </Section>
            </Wrapper>
            <ServicesRichSnippet data={node} />
        </>
    );
};

export const query = graphql`
  query {
    allDirectusPage(filter: { slug: { eq: "digital-plus-package" } }) {
      edges {
        node {
          title
          slug
          description
          robots
          canonical
        }
      }
    }
  }
`;
